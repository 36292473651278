@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
.labelClassname {
  position: relative;
  cursor: pointer;
  color: #14191f;
  font-size: 20px;
  margin: 3px;
}

.checkbox-input,
.radio-input {
  opacity: 0;
}

/*Radio box*/

.radio-input + .label-text:before {
  content: "\f10c";
  font-family: "FontAwesome";
  line-height: 1;
  width: 1em;
  display: inline-block;
  margin-right: 3px;
}

.radio-input:checked + .label-text:before {
  content: "\f192";
  color: #14191f;
  animation: effect 250ms ease-in;
}

.radio-input:disabled + .label-text {
  color: #aaa;
}

.radio-input:disabled + .label-text:before {
  content: "\f111";
  color: #ccc;
}

/*Check box*/
.checkbox-input + .label-text:before {
  content: "\f096";
  font-family: "FontAwesome";
  line-height: 1;
  width: 1em;
  display: inline-block;
  margin-right: 3px;
}

.checkbox-input:checked + .label-text:before {
  content: "\f14a";
  color: #2c3e50;
  animation: effect 250ms ease-in;
}

.checkbox-input:disabled + .label-text {
  color: #aaa;
}

.checkbox-input:disabled + .label-text:before {
  content: "\f0c8";
  color: #ccc;
}

@keyframes effect {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

